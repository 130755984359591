var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "success-div"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/integral/icon-5.jpg")
    }
  }), _c('div', {
    staticClass: "tip"
  }, [_vm._v("提交成功")]), _c('van-button', {
    staticClass: "button",
    attrs: {
      "color": "#C70C0C",
      "type": "danger",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.replace('/mine');
      }
    }
  }, [_vm._v("提交")])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };